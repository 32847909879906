/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import React from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import FavoriteTrips from '../../account/FavoriteTrips';
import { BookingWidget } from '../../components/widgets';
import AccountLayout from '../../account/AccountLayout';
import * as analytics from '../../utils/analytics';

export default ({ location, pageContext }) => {
  analytics.usePageCategory('matkustajat');
  const translate = getTranslate(useSelector(state => state.localize));
  const title = translate('account.favoriteTrips.title');

  return (
    <AccountLayout
      title={title}
      paths={pageContext.paths}
      locale={pageContext.locale || 'en'}
      sidebar={
        <Box>
          <BookingWidget sidebar sx={{ mb: 3 }} showFavoriteTrips={false} />
        </Box>
      }
    >
      <FavoriteTrips />
    </AccountLayout>
  );
};
