/** @jsx jsx */
import { jsx, Box, Flex, Styled } from 'theme-ui';
import React, { useEffect, useState, useCallback, Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useDispatch, useSelector } from 'react-redux';
import * as api from '../utils/api';
import Spinner from '../components/Spinner';
import { CalendarIcon, StarIcon } from '../components/Icon';
import { Button } from '../components';
import { formatDate } from '../utils/formatters';
import getLanguage from '../utils/getLanguage';
import { DateInput } from '../components/DateField';
import moment from 'moment';
import { fetchTrips } from '../state/favoriteTrips';

class DateButton extends Component {
  render() {
    const { value, onClick } = this.props;
    return (
      <Button
        variant="plain"
        sx={{
          display: 'flex',
          flex: 1,
          alignItems: 'center',
          borderRadius: 0,
        }}
        onClick={onClick}
      >
        <CalendarIcon sx={{ mr: 2, flex: 'none' }} />
        <Box>{value ? value : moment().format('DD.MM.YYYY')}</Box>
      </Button>
    );
  }
}

const Trip = ({ trip: { arrival, departure } }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const language = useSelector(state => getLanguage(state));

  const [departureDate, setDepartureDate] = useState();
  const selectDate = useCallback(
    v => {
      setDepartureDate(v);
    },
    [setDepartureDate]
  );

  const redirectToTicketService = useCallback(() => {
    let url = process.env.GATSBY_TICKET_API_URL + '/timetable?';
    url += `lang=${language}`;
    url += `&departurePlaceId=${departure.id || ''}`;
    url += `&arrivalPlaceId=${arrival.id || ''}`;
    url += `&departureDate=${formatDate(departureDate)}`;
    // url += `&returnDate=${returnDate || ''}`;
    window.location.assign(url);
  });
  return (
    <Flex
      sx={{
        width: '100%',
        mt: 2,
      }}
    >
      <Flex
        sx={{
          mr: 4,
          mb: 2,
          bg: 'blueLighter',
          borderRadius: 5,
          minWidth: '48px',
          height: '48px',
          justifyContent: 'center',
          alignItems: 'center',
          display: ['none', 'flex'],
        }}
      >
        <StarIcon sx={{ width: 14, height: 14, color: 'primary' }} />
      </Flex>
      <Flex
        sx={{
          flex: 1,
          borderBottom: '1px solid',
          borderBottomColor: 'border',
        }}
      >
        <Box sx={{ flexGrow: 1, width: '50%', fontSize: 'small' }}>
          <Box sx={{ fontWeight: 'medium', fontSize: 2 }}>{departure.region}</Box>
          {departure.name}
        </Box>
        <span
          sx={{
            px: 2,
            fontSize: '25px',
            fontWeight: 'medium',
            alignSelf: 'center',
          }}
        >
          &rarr;
        </span>
        <Box sx={{ flexGrow: 1, width: '50%', fontSize: 'small' }}>
          <Box sx={{ fontWeight: 'medium', fontSize: 2 }}>{arrival.region}</Box>
          {arrival.name}
        </Box>
        <Box
          sx={{
            minWidth: 'min-content',
            alignSelf: 'center',
            pr: 2,
            display: ['none', 'block'],
          }}
        >
          <DateInput
            selected={departureDate}
            onChange={selectDate}
            disabledKeyboardNavigation
            dateFormat="dd.MM.yyyy"
            customInput={<DateButton />}
          />
        </Box>
        <Button sx={{ flex: 'none', alignSelf: 'center' }} as="a" variant="small" onClick={redirectToTicketService}>
          {translate('account.favoriteTrips.search')}
        </Button>
      </Flex>
    </Flex>
  );
};

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const trips = useSelector(state => state.favoriteTrips && state.favoriteTrips.trips);
  const tripsLoading = useSelector(state => state.favoriteTrips && state.favoriteTrips.isLoading);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!tripsLoading && !trips) {
      dispatch(fetchTrips());
    }
  }, [dispatch]);

  return (
    <Box>
      <Styled.h1>{translate('account.favoriteTrips.title')}</Styled.h1>
      {!trips && (
        <div sx={{ position: 'relative', height: '100px' }}>
          <Spinner />
        </div>
      )}
      {trips && trips.length === 0 ? (
        <div>{translate('account.favoriteTrips.noTrip')}</div>
      ) : (
        <div>
          {(trips || []).map(trip => (
            <Trip key={trip.id} trip={trip}></Trip>
          ))}
        </div>
      )}
    </Box>
  );
};
